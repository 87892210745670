var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("จัดการลอตเตอรี่")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("สรุปยอด")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(" รวมจำนวน Lottery ในระบบ: "),_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" "+_vm._s(_vm.totalLot)+" ")]),_vm._v(" ใบ / ยอดคงเหลือที่ยังไม่ขาย: "),_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" "+_vm._s(_vm.totalNotSell)+" ")]),_vm._v(" ใบ ")],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersLottoReportQuota,"items":_vm.reportDataQuota,"search":_vm.search,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.urlImage",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-2",attrs:{"src":item.urlImage,"max-height":"50px","max-width":"100px"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'PENDING')?_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(" รอการชำระเงิน ")]):_vm._e(),(item.status == 'รอดำเนินการ')?_c('v-chip',{attrs:{"color":"yello","dark":""}},[_vm._v(" รอดำเนินการ ")]):_vm._e(),(item.status == 'SUCCESS')?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" ชำระเงินสำเร็จ ")]):_vm._e(),(item.status == 'มีปัญหา')?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" มีปัญหา ")]):_vm._e(),(item.status == 'ยกเลิก')?_c('v-chip',{attrs:{"color":"brown","dark":""}},[_vm._v(" ยกเลิก ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.goToDupp(item)}}},[_vm._v("mdi-content-copy")]),_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.UpdateLotto(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteLotto(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersLottoReport,"items":_vm.reportData,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"item.urlImage",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-2",attrs:{"src":item.urlImage,"max-height":"50px","max-width":"100px"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'PENDING')?_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(" รอการชำระเงิน ")]):_vm._e(),(item.status == 'รอดำเนินการ')?_c('v-chip',{attrs:{"color":"yello","dark":""}},[_vm._v(" รอดำเนินการ ")]):_vm._e(),(item.status == 'SUCCESS')?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" ชำระเงินสำเร็จ ")]):_vm._e(),(item.status == 'มีปัญหา')?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" มีปัญหา ")]):_vm._e(),(item.status == 'ยกเลิก')?_c('v-chip',{attrs:{"color":"brown","dark":""}},[_vm._v(" ยกเลิก ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.goToDupp(item)}}},[_vm._v("mdi-content-copy")]),_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.UpdateLotto(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteLotto(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"เลือกงวดที่ออกรางวัล","prepend-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menufrom),callback:function ($$v) {_vm.menufrom=$$v},expression:"menufrom"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menufrom = false}},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.getAllLotto()}}},[_vm._v("ค้นหา")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.goToCreate()}}},[_vm._v("เพิ่มลอตเตอรี่")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersLotto,"items":_vm.items,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"item.urlImage",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-2",attrs:{"src":item.urlImage,"max-height":"50px","max-width":"100px"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.goToDupp(item)}}},[_vm._v("mdi-content-copy")]),_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.UpdateLotto(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteLotto(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }